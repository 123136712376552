@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

html,body,#root,.app{
  height: 100%;
  width: 100%;
  font-family: "Rubik" , sans-serif;
}

@font-face { font-family: Riley1; src: url('./fonts/ZilapOrionFuturistic-DzA3.ttf'); }
@font-face { font-family: Riley2; src: url('./fonts/eurasiaexregular.ttf'); }
@font-face { font-family: HardRace; src: url('./fonts/HARD_RACE.OTF'); }



.fc-event-title {
  margin: 1px;
  padding: 1px;
  white-space: normal;
}

.fc-event-time {
  margin: 1px;
  padding: 1px;
  white-space: normal;
  font-size: .9em;
  width: 3em;
}

.fc-event {
  margin: 1px;
  padding: 1px;
  white-space: normal;
}